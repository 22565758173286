import React, { useCallback, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import { getCookieNames, fetchAvailableIngredientCounts, isAvailable } from "../util/OrderUtil"
import CookieCard from "../ui/CookieCard"
import {
  Card,
  Divider,
} from "semantic-ui-react"

const Page = ({ location, order, setCookie, availableIngredientCounts, setAvailableIngredientCounts, }) => {
  const onSetCookie = useCallback(cookie => {
    setCookie(cookie);
    navigate("/order-dry-toppings");
  }, [setCookie]);

  useEffect(() => {
    fetchAvailableIngredientCounts(setAvailableIngredientCounts);
  }, [setAvailableIngredientCounts]);

  return (
    <Layout location={location} to="/order-dry-toppings" showBack={false} header="Choose a cookie.">
      <Card.Group centered>
      {getCookieNames().map((cookieName, i) => (
        <CookieCard key={i} cookie={cookieName} onSetCookie={onSetCookie} available={isAvailable(availableIngredientCounts, "cookies", cookieName)}/>
      ))}
      </Card.Group>
      <Divider hidden/>
      <Divider hidden/>
      <Divider hidden/>
    </Layout>
  );
};

export default connectStore(Page);
