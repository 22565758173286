import React from "react"
import { getData, getImage } from "../util/OrderUtil"
import {
  Image,
  Card,
  Header,
  Icon,
  List,
  Label,
} from "semantic-ui-react"

const noShadowStyle = {
  boxShadow: "none",
};

const inlineContentStyle = {
  display: "inline-block",
  //paddingLeft: "0.5em",
};

const imageStyleProp = {
  height: "35px",
};

export const CookieLabel = ({ cookie, hideLabel=false }) => {
  const cookieData = getData(cookie);
  const { name, label } = cookieData;
  const cookieImage = getImage(name, { style: imageStyleProp });

  return (
    <>
      <Icon.Group style={inlineContentStyle}>
        <Image
          rounded
          size="tiny"
        >
          {cookieImage}
        </Image>
      </Icon.Group>
      {!hideLabel &&
        <List.Content>
          <List.Header as="h3">
            {label}
          </List.Header>
        </List.Content>
      }
    </>
  );
};

const CookieCard = ({ cookie, onSetCookie=null, circular=true, available=true }) => {
  const cookieData = getData(cookie);
  const { name, label, description } = cookieData;
  const image = getImage(name);

  return (
    circular ?
      <Card
        centered
        raised
        link
        onClick={(!available || onSetCookie == null) ? null : () => onSetCookie(cookie)}
        style={noShadowStyle}>
        <Card.Content>
          <Image circular disabled={!available}>{image}</Image>
        </Card.Content>
        <Card.Header>
          <Header as="h3">
            {!available && <><Label basic color="red" size="big">Out of Stock!</Label><br/></>}
            {label}
          </Header>
        </Card.Header>
        <Card.Meta>
          {description}
        </Card.Meta>
      </Card>
    :
    <Card
      centered
      raised
      link
      onClick={(!available || onSetCookie == null) ? null : () => onSetCookie(cookie)}
    >
      {image}
      <Card.Content>
        <Card.Header>
          <Header as="h3">
            {label}
          </Header>
        </Card.Header>
        <Card.Meta>
          {available ? description : "Out of Stock!"}
        </Card.Meta>
      </Card.Content>
    </Card>
  );
}

export default CookieCard;
